/* eslint-disable camelcase */
import {useEffect, useState, useRef} from 'react';
import type {CalculationResult} from '../lib/calculate-price';

export type CalculatePriceProps = {
    vehicle_type_id: string;
    from: Date;
    to: Date;
    insurance_id: string;
    is_ride_to_a_foreign_country?: boolean;
    expected_kilometer?: number;
    discount_id?: string;
    voucher?: string;
    selected_accessories?: string[];
};

export const useCalculatePrice = ({
    vehicle_type_id,
    from,
    to,
    insurance_id,
    is_ride_to_a_foreign_country,
    expected_kilometer,
    discount_id,
    voucher,
    selected_accessories,
}: CalculatePriceProps): {
    priceInformation: CalculationResult;
    loading: boolean;
} => {
    const [priceInformation, setPriceInformation] =
        useState<CalculationResult | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await fetch('/api/calculate-price', {
                method: 'POST',

                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    vehicle_type_id,
                    from: from.toISOString(),
                    to: to.toISOString(),
                    insurance_id,
                    is_ride_to_a_foreign_country,
                    expected_kilometer,
                    discount_id,
                    voucher,
                    selected_accessories,
                }),
            });

            const body = await response.json();

            setPriceInformation(body as CalculationResult);
            setLoading(false);
        };

        if (vehicle_type_id && insurance_id && from && to) {
            fetchData();
        }
    }, [
        vehicle_type_id,
        from,
        to,
        insurance_id,
        is_ride_to_a_foreign_country,
        expected_kilometer,
        discount_id,
        voucher,
        selected_accessories,
    ]);

    return {priceInformation, loading};
};
