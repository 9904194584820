/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/require-default-props */
import React, {ReactNode, Fragment} from 'react';
import {
    Box,
    Button,
    Img,
    Stack,
    StackProps,
    Text,
    useBreakpointValue,
    HStack,
    Tag,
    SimpleGrid,
    Icon,
    Flex,
    AspectRatio,
    GridItem,
} from '@chakra-ui/react';
import {Link} from '@chakra-ui/next-js';
import {FaChevronRight, FaUser, FaSuitcase, FaCheck} from 'react-icons/fa';
import differenceInHours from 'date-fns/differenceInHours';
import {PriceTag, formatPrice} from './price-tag';
import type {CalculationResult} from '../lib/calculate-price';
import {
    VehicleTypesQuery,
    Base_Category_Enum,
} from '../generated/graphql.types';
import {gearshiftToText} from '@/lib/enum-helpers';

export type VehicleTypeCardProps = {
    priceInformation?: CalculationResult;
    name: string;
    imageUrl: string;
    categories?: VehicleTypesQuery['vehicle_type'][0]['vehicle_type_categories'][0]['vehicle_category'][];
    seats: number;
    gearshift?: VehicleTypesQuery['vehicle_type'][0]['gearshift'];
    luggageNormal?: number;
    luggageMinor?: number;
    doors?: number;
    cargo_height?: number;
    cargo_length?: number;
    cargo_max?: number;
    cargo_width?: number;
    hasAirConditioner?: boolean;
    selectedFrom: Date;
    selectedTo: Date;
    selectedBaseCategory: Base_Category_Enum;
    href?: string;
    hideNavigationButton?: boolean;
    additionalData?: {label: string; value: ReactNode}[];
    isDisabled?: boolean;
} & StackProps;

export const VehicleTypeCard = ({
    priceInformation,
    name,
    imageUrl,
    categories,
    seats,
    gearshift,
    luggageNormal,
    luggageMinor,
    doors,
    hasAirConditioner,
    selectedFrom,
    selectedTo,
    selectedBaseCategory,
    href,
    hideNavigationButton,
    additionalData,
    cargo_height,
    cargo_length,
    cargo_max,
    cargo_width,
    isDisabled,
    ...props
}: VehicleTypeCardProps) => {
    const durationInHours = differenceInHours(selectedTo, selectedFrom, {
        roundingMethod: 'ceil',
    });

    return (
        <Stack spacing={useBreakpointValue({base: '4', md: '5'})} {...props}>
            <Box position="relative">
                <AspectRatio ratio={16 / 9}>
                    <Img
                        src={imageUrl}
                        alt={name}
                        objectFit="cover"
                        borderRadius={useBreakpointValue({
                            base: 'md',
                            md: 'xl',
                        })}
                    />
                </AspectRatio>

                <HStack spacing="3" position="absolute" bottom="3" left="3">
                    {categories?.map((category) => (
                        <Tag
                            key={category.id}
                            bg="brand.500"
                            color="white"
                            fontWeight="semibold"
                        >
                            {category.is_group
                                ? `Kategorie: ${category.name}`
                                : category.name}
                        </Tag>
                    ))}
                </HStack>
            </Box>
            <Stack>
                <Stack spacing="1">
                    <HStack>
                        <Text fontSize="lg" fontWeight="bold" color="brand.500">
                            {name}
                        </Text>
                        <Text fontSize="sm" color="muted">
                            oder ähnlich
                        </Text>
                    </HStack>
                    <PriceTag
                        price={
                            (priceInformation?.total?.allRoundPrice || 0) +
                            (priceInformation?.total?.discount || 0)
                        }
                        salePrice={
                            priceInformation?.total?.discount
                                ? priceInformation?.total?.allRoundPrice
                                : undefined
                        }
                    />
                </Stack>

                <SimpleGrid columns={2} fontSize="sm">
                    {additionalData?.map((a) => (
                        <Fragment key={a.label}>
                            <Text>{a.label}</Text>
                            <Text textAlign="right">{a.value}</Text>
                        </Fragment>
                    ))}
                    {priceInformation && (
                        <>
                            <GridItem colSpan={2}>
                                <Text>
                                    {`Inkl. ${priceInformation?.total?.includedKilometer} Freikilometer`}
                                </Text>
                            </GridItem>
                            <GridItem colSpan={2} pb={2}>
                                <Text>{`Inkl. Vollkaskoversicherung`}</Text>
                            </GridItem>
                            {priceInformation.duration > 1 && (
                                <>
                                    <Text>Pro Tag</Text>
                                    <Flex justify="flex-end" align="center">
                                        <PriceTag
                                            price={
                                                priceInformation.duration >= 1
                                                    ? priceInformation.total
                                                          .allRoundPrice /
                                                      priceInformation.duration
                                                    : priceInformation.total
                                                          .allRoundPrice
                                            }
                                        />
                                    </Flex>
                                </>
                            )}
                        </>
                    )}
                    <Text>Sitzplätze</Text>
                    <HStack
                        spacing="0.5"
                        alignItems="center"
                        justifyContent="end"
                    >
                        {Array.from({length: seats}).map((_, idx) => (
                            <Icon key={idx} as={FaUser} />
                        ))}
                    </HStack>
                    {selectedBaseCategory === Base_Category_Enum.Car && (
                        <>
                            <Text>Ladevolumen</Text>
                            <HStack
                                spacing="1"
                                alignItems="center"
                                justifyContent="end"
                            >
                                {Array.from({length: luggageMinor}).map(
                                    (_, idx) => (
                                        <Icon
                                            boxSize={3}
                                            key={idx}
                                            as={FaSuitcase}
                                        />
                                    )
                                )}
                                {Array.from({length: luggageNormal}).map(
                                    (_, idx) => (
                                        <Icon key={idx} as={FaSuitcase} />
                                    )
                                )}
                            </HStack>
                        </>
                    )}
                    {selectedBaseCategory === Base_Category_Enum.Transporter &&
                        cargo_length &&
                        cargo_max && (
                            <>
                                <Text>Ladefläche</Text>
                                <Text textAlign="right">
                                    L {cargo_length.toLocaleString('de-AT')}m B{' '}
                                    {cargo_width.toLocaleString('de-AT')}m H{' '}
                                    {cargo_height.toLocaleString('de-AT')}m
                                </Text>
                                <Text>Maximale Nutzlast</Text>
                                <Text textAlign="right">
                                    {cargo_max.toLocaleString('de-AT')}kg
                                </Text>
                            </>
                        )}
                    <Text>Schaltung</Text>
                    <Text textAlign="right">{gearshiftToText(gearshift)}</Text>
                    {selectedBaseCategory === Base_Category_Enum.Car && (
                        <>
                            <Text>Türen</Text>
                            <Text textAlign="right">{doors}</Text>
                            <Text>Klimanlage</Text>
                            <Text textAlign="right">
                                {hasAirConditioner && <Icon as={FaCheck} />}
                            </Text>
                        </>
                    )}

                    {selectedBaseCategory === Base_Category_Enum.Transporter &&
                        durationInHours >= 3 &&
                        durationInHours <= 24 &&
                        priceInformation &&
                        priceInformation?.total?.allRoundPrice && (
                            <GridItem colSpan={2}>
                                <Text pt={2}>
                                    Somit kostet Sie das Fahrzeug für den von
                                    Ihnen gewünschten Zeitraum{' '}
                                    <Text
                                        as="span"
                                        fontWeight="bold"
                                        color="brand.500"
                                    >
                                        {formatPrice(
                                            priceInformation?.total
                                                ?.allRoundPrice /
                                                durationInHours
                                        )}{' '}
                                        / Stunde.
                                    </Text>
                                </Text>
                            </GridItem>
                        )}
                </SimpleGrid>
            </Stack>
            {!hideNavigationButton && (
                <Stack align="center">
                    <Button
                        as={Link}
                        href={href}
                        colorScheme="yellow"
                        isDisabled={isDisabled}
                        rightIcon={<FaChevronRight />}
                    >
                        Auswählen
                    </Button>
                </Stack>
            )}
        </Stack>
    );
};
