import {Gearshift_Enum, Fuel_Enum} from '@/generated/graphql.types';

export const gearshiftToText = (gearshift: Gearshift_Enum) => {
    switch (gearshift) {
        case Gearshift_Enum.Automatic:
            return 'Automatik';
        case Gearshift_Enum.Manual:
            return 'Manuell';
        default:
            return gearshift;
    }
};

export const fuelToText = (fuel: Fuel_Enum) => {
    switch (fuel) {
        case Fuel_Enum.Diesel:
            return 'Diesel';
        case Fuel_Enum.Electricity:
            return 'Elektrisch';
        case Fuel_Enum.Petrol:
            return 'Benzin';
        default:
            return fuel;
    }
};
