import React from 'react';
import Image from 'next/image';
import {
    Box,
    Container,
    Flex,
    Text,
    Link,
    useColorModeValue,
} from '@chakra-ui/react';

const Header = () => (
    <Box
        as="nav"
        bg="bg-surface"
        boxShadow={useColorModeValue('sm', 'sm-dark')}
    >
        <Container py={{base: '3', lg: '4'}}>
            <Flex justify="space-between" align="center">
                <Link href="https://drhartl.at">
                    <Image
                        width="170"
                        height="78"
                        alt="Preisrechner"
                        src="/drhartl_logo.png"
                    />
                </Link>
                <Box>
                    <Text fontWeight="bold">
                        Hotline:{' '}
                        <Link href="tel:+4315444343">+43 (1) 544 43 43</Link>
                    </Text>
                    <Text fontSize="xs" color="muted">
                        Montag bis Freitag 07:00 - 17:00 Uhr
                    </Text>
                </Box>
            </Flex>
        </Container>
    </Box>
);

export default Header;
