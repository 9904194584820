import React, {ReactNode} from 'react';
import {Box, Container} from '@chakra-ui/react';
import Header from './header';

export type LayoutProps = {
    children: ReactNode;
};

const Layout = ({children}: LayoutProps) => (
    <Box as="section">
        <Header />
        <Container pt={{base: '8', lg: '12'}} pb={{base: '12', lg: '24'}}>
            {children}
        </Container>
    </Box>
);

export default Layout;
