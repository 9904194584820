/* eslint-disable react/require-default-props */
import React from 'react';
import {
    HStack,
    StackProps,
    Text,
    TextProps,
    useColorModeValue as mode,
} from '@chakra-ui/react';

export type PriceTagProps = {
    currency?: string;
    price: number;
    salePrice?: number;
    rootProps?: StackProps;
    priceProps?: TextProps;
    salePriceProps?: TextProps;
};

export type FormatPriceOptions = {locale?: string; currency?: string};

export function formatPrice(
    value: number,
    opts: {locale?: string; currency?: string} = {}
) {
    const {locale = 'de-AT', currency = 'EUR'} = opts;
    const formatter = new Intl.NumberFormat(locale, {
        currency,
        style: 'currency',
        maximumFractionDigits: 2,
    });
    return formatter.format(value);
}

export const PriceTag = ({
    price,
    currency = 'EUR',
    salePrice,
    rootProps,
    priceProps,
    salePriceProps,
}: PriceTagProps) => (
    <HStack spacing="1" {...rootProps} fontSize="xl">
        <Price isOnSale={!!salePrice} textProps={priceProps}>
            {formatPrice(price, {currency})}
        </Price>
        {salePrice && (
            <SalePrice {...salePriceProps}>
                {formatPrice(salePrice, {currency})}
            </SalePrice>
        )}
    </HStack>
);

export type PriceProps = {
    children?: React.ReactNode;
    isOnSale?: boolean;
    textProps?: TextProps;
};

const Price = ({isOnSale, children, textProps}: PriceProps) => {
    const defaultColor = mode('gray.900', 'gray.400');
    const onSaleColor = mode('gray.400', 'gray.900');
    const color = isOnSale ? onSaleColor : defaultColor;
    return (
        <Text
            as="span"
            fontWeight="medium"
            color={color}
            textDecoration={isOnSale ? 'line-through' : 'none'}
            {...textProps}
        >
            {children}
        </Text>
    );
};

const SalePrice = (props: TextProps) => (
    <Text
        as="span"
        fontWeight="semibold"
        color={mode('gray.800', 'gray.100')}
        {...props}
    />
);
